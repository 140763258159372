<template>
  <b-tabs>
    <b-tab active>
      <template #title>
        <feather-icon icon="AlignJustifyIcon" class="mr-50" size="16" />
        <span> {{ $t('Tüm Kayıtlar') }}  </span>
      </template>
      <crud-button
        :onClickList="fetchTicketsAll"
        :showAddNew="false"
      />
      <destek-filter />
      <b-card no-body class="mb-0">
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>{{ $t("tane göster") }}</label>
            </b-col>
          </b-row>
        </div>

        <b-table
          ref="ticketsTable"
          class="position-relative"
          :items="getTickets"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          :per-page="perPage"
          :current-page="currentPage"
          empty-text="Kayıt bulunamadı"
        >
          <template #cell(sKayitNo)="data">
            {{ data.item.ticket.sKayitNo }}
          </template>
          <template #cell(sKonu)="data">
            {{ data.item.ticket.sKonu }}
          </template>
          <template #cell(ticketOncelikTuru)="data">
            <b-badge
              :variant="
                data.item.ticket.ticketOncelikTuru == 2 ? 'info' : 'danger'
              "
              class="badge"
            >
              <span>{{
                resolveOncelikTur(data.item.ticket.ticketOncelikTuru)
              }}</span>
            </b-badge>
          </template>
          <template #cell(departmentTuru)="data">
            {{ resolveDepartmanTur(data.item.ticket.departmentTuru) }}
          </template>
           <template #cell(ticketUygulamaTuru)="data">
            {{ resolveTicketUygulamaTur(data.item.ticket.ticketUygulamaTuru) }}
          </template>
          <template #cell(dtOlusTarih)="data">
            {{ formatDateToDMYT(data.item.ticket.dtOlusTarih) }}
          </template>
          <template #cell(ilgiliPersonelAd)="data">
            {{ data.item.ilgiliPersonelAd }}
          </template>
          <template #cell(actions)="data">
            <b-button-group>
              <b-button
                size="sm"
                v-if="isAdmin()"
                @click="
                  $router.push({
                    name: 'manage-support-detail-open',
                    params: { detailId: data.item.ticket.id },
                  })
                "
                variant="primary"
              >
                <span class="align-middle">Detay</span>
              </b-button>
              <b-button
                size="sm"
                v-if="data.item.ilgiliPersonelAd == null"
                class="mr-2"
                @click="assign(data.item.ticket.id)"
                variant="warning"
              >
                <span class="align-middle">Bana Ata</span>
              </b-button>
            </b-button-group>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted"
                >{{ dataMeta.of }} öğeden {{ dataMeta.from }} -
                {{ dataMeta.to }} arası gösteriliyor</span
              >
            </b-col>

            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="toplamTicket"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon icon="UserIcon" class="mr-50" size="16" />
        <span>{{ $t('Bana Ait Kayıtlar') }}</span>
      </template>
      <crud-button
        :onClickList="fetchMyTickets"
        :showAddNew="false"
      />
      <my-destek-filter />
      <b-card no-body class="mb-0">
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <v-select
                v-model="perMyPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>{{ $t("tane göster") }}</label>
            </b-col>
          </b-row>
        </div>

        <b-table
          ref="myTicketsTable"
          class="position-relative"
          :items="getMyTickets"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          :per-page="perMyPage"
          :current-page="currentMyPage"
          empty-text="Kayıt bulunamadı"
        >
          <template #cell(sKayitNo)="data">
            {{ data.item.ticket.sKayitNo }}
          </template>
          <template #cell(sKonu)="data">
            {{ data.item.ticket.sKonu }}
          </template>
          <template #cell(ticketOncelikTuru)="data">
            <b-badge
              :variant="
                data.item.ticket.ticketOncelikTuru == 2 ? 'info' : 'danger'
              "
              class="badge"
            >
              <span>{{
                resolveOncelikTur(data.item.ticket.ticketOncelikTuru)
              }}</span>
            </b-badge>
          </template>
          <template #cell(departmentTuru)="data">
            {{ resolveDepartmanTur(data.item.ticket.departmentTuru) }}
          </template>
          <template #cell(ticketUygulamaTuru)="data">
            {{ resolveTicketUygulamaTur(data.item.ticket.ticketUygulamaTuru) }}
          </template>
          <template #cell(dtOlusTarih)="data">
            {{ formatDateToDMYT(data.item.ticket.dtOlusTarih) }}
          </template>
          <template #cell(ilgiliPersonelAd)="data">
            {{ data.item.ilgiliPersonelAd }}
          </template>
          <template #cell(actions)="data">
            <!-- Update -->
            <b-button
              size="sm"
              @click="
                $router.push({
                  name: 'manage-support-detail-open',
                  params: { detailId: data.item.ticket.id },
                })
              "
              variant="primary"
            >
              <span class="align-middle">Detay</span>
            </b-button>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted"
                >{{ myDataMeta.of }} öğeden {{ myDataMeta.from }} -
                {{ myDataMeta.to }} arası gösteriliyor</span
              >
            </b-col>

            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="toplamTicket"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-tab>
  </b-tabs>
</template>

<script>
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'
import { getUserRole, Role } from '@/auth/utils'
import AlertService from '@/common/alert.service'
import useSupport, { assignTicket } from './useDestek';
import DestekFilter from './DestekFilter.vue';
import MyDestekFilter from './MyDestekFilter.vue';

export default {
  components: {
    MyDestekFilter,
    DestekFilter,
  },
  methods: {
    isAdmin() {
      return getUserRole() === Role.SuperAdmin
    },
    assign(ticketId) {
      assignTicket({ ticketId }, this)
        .then(response => {
          if (response.statusCode === 200) {
            this.$toast({
              component: ToastificationSuccess,
              position: 'top-right',
              props: { text: response.message },
            })
            this.fetchMyTickets();
            this.fetchTicketsAll();
          } else {
            AlertService.error(this, response.message)
          }
        })
    },
  },
  setup() {
    const {
      fetchTicketsAll,
      fetchMyTickets,
      getTickets,
      getMyTickets,
      tableColumns,
      perPage,
      perMyPage,
      currentPage,
      currentMyPage,
      toplamTicket,
      toplamMyTicket,
      dataMeta,
      myDataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      ticketsTable,
      myTicketsTable,
      resolveDepartmanTur,
      resolveTicketUygulamaTur,
      resolveOncelikTur,

      formatDateToDMYT,
    } = useSupport();

    return {
      fetchTicketsAll,
      fetchMyTickets,
      getTickets,
      getMyTickets,
      tableColumns,
      perPage,
      perMyPage,
      currentPage,
      currentMyPage,
      toplamTicket,
      toplamMyTicket,
      dataMeta,
      myDataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      ticketsTable,
      myTicketsTable,
      assignTicket,
      resolveDepartmanTur,
      resolveTicketUygulamaTur,
      resolveOncelikTur,

      formatDateToDMYT,
    }
  },
}
</script>

<style>
</style>
