<template>
  <b-card no-body>
    <b-card-body>
      <b-row>
        <b-col cols="12" md="3"
          class="mb-md-0 mb-2">
          <label>{{ $t('Aciliyet') }}</label>
          <v-select
            v-model="ticketOncelikTuruFilter"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="ticketOncelikTuruOptions"
            class="w-100"
            :reduce="val => val.value" />
        </b-col>

        <b-col cols="12" md="3"
          class="mb-md-0 mb-2">
          <label>{{ $t('Müşteri') }}</label>
          <musteri-selection :musteri-select.sync="musteriIdFilter" />
        </b-col>

        <b-col cols="12" md="3"
          class="mb-md-0 mb-2">
          <label>{{ $t('Arama') }}</label>
          <b-form-input
            v-model="queryFilter"
            class="d-inline-block mr-1"
            :placeholder="$t('Ara...')" />
        </b-col>

      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>

// Options
import StaticOptions from '@/common/options/StaticOptions'
import MusteriSelection from '../selection/MusteriSelection.vue'

export default {
  components: {
    MusteriSelection,
  },
  data() {
    return {
      ticketOncelikTuruOptions: StaticOptions.ticketOncelikTuruOptions,
    }
  },
  computed: {
    ticketOncelikTuruFilter: {
      get() {
        return this.$store.getters['ticketYonetim/getMyTicketFilter'].ticketOncelikTuru
      },
      set(value) {
        this.$store.commit('ticketYonetim/setMyTicketFilter', { key: 'ticketOncelikTuru', value })
      },
    },
    musteriIdFilter: {
      get() {
        return this.$store.getters['ticketYonetim/getMyTicketFilter'].musteri_Id
      },
      set(value) {
        this.$store.commit('ticketYonetim/setMyTicketFilter', { key: 'musteri_Id', value })
      },
    },
    queryFilter: {
      get() {
        return this.$store.getters['ticketYonetim/getMyTicketFilter'].query
      },
      set(value) {
        this.$store.commit('ticketYonetim/setMyTicketFilter', { key: 'query', value })
      },
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
